
.Contents{
    width: 100%;
    height: 100%; 
}
.Contents .list_data{
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: row;
}