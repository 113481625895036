.ContentList {
    width: auto;
    height: calc(100% - 90px);
    flex-grow: 1;
    padding: 20px;
    background-color: #f4f6f9;
    overflow-y: auto;
}
.ContentList .contents{
    width: auto;
    flex-grow: 1;
    padding: 16px 16px 10px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 20px;
}
.ContentList .register_data{
    background-color: #e09641;
    padding: 8px 20px;
    border-radius: 8px;
    width: max-content;
    position: absolute;
    right: 26px;
    top: 15px;
    cursor: pointer;
}
.ContentList .register_text{
    color: #935003;
    font-weight: bold;
}