
body, html{
    height: 100%;
}
body{
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    overflow-y: hidden;
    font-family: 'Poppins';
    background-color: #fff;
}
a { 
    text-decoration:none;
    cursor:pointer;
    color:inherit;
}
ul{
    margin: 0px;
    padding: 0px;
}
.root{
    width: 100%;
    height: 100%;
}

/* input, textarea and selected */
input{  
    height: 40px;
    padding: 0px 10px;
}
textarea{
    resize: none;
    height: 52px;
    padding: 10px;
}
select{
    padding: 2px;
    height: 40px;
}
input, textarea, select{
    font-size: 14px;
    font-weight: 400;
    color: #363636;
    font-family: "Poppins";
    border-radius: 5px;  
    border: solid 2px #e2e3e4;
    width: -webkit-fill-available;
}
::placeholder { 
    color:#b9b9b9; 
    font-style: italic;
}
/* end */

/* scrollbar */
::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px rgb(0 187 209); 
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px rgb(0 187 209); 
}
/* end */

/* table */
table{
    width: 100%;
    font-size: 14px;
    border-spacing: 0px;
    font-family: 'Inter';
    text-transform: capitalize;
}
th{
    color: #2D3C53;
    font-weight: 700;
    border-bottom: 2px solid #C5D2E8;
}
td {
    border-bottom: 1px solid #C5D2E8;
}
td, th{
    cursor: default;
    padding: 0.85rem;
    text-align: left;
}
tr:hover td{
    background: #C5D2E8;
    transition-duration: 50ms;
}
/* end */

/* svg icons with fixed size */
.svg_opt{
    width: 24px;
    height: 24px;
    transition: all .2s cubic-bezier(.4,0,1,1) 0s;
}
/* end */