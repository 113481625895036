
.ListTopic{
    width: 100%;
    height: 72px;
    background: #c5d2e8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.ListTopic .logotipo{
    width: 264px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d3c53;
}
.ListTopic .logo{
    width: auto;
    height: 60px;
}
.ListTopic .show_name_menu{
    padding: 0 20px;
    width: auto;
    height: 100%;
    align-items: center;
    color: #2d3c53;
    display: flex;
    flex-grow: 1;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    gap: 10px;
    text-transform: capitalize;
}
.ListTopic .settings{
    text-align: right;
    margin-right: 20px;
    cursor: pointer;
}