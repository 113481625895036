
.ListMenu{
    width: 264px;
    height: calc(100% - 90px);
    background: #2d3c53;
    border-top: 1px solid #86a0cd;
    padding: 20px 0px;
    overflow-y: auto;
}
.ListMenu .list_menu_left{
    margin: 20px;
}
.ListMenu .menu_name{
    font-family: 'Inter';
    font-size: 14px;
    padding: 10px 14px;
    cursor: pointer;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
}
.ListMenu .upload_xml{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #935003;
    color: #FFC400;
    border-radius: 8px;
}
.ListMenu .active{
    color: #FFC400;
}
.ListMenu .name_sub_menu{
    padding: 10px 14px;
    display: flex;
    text-transform: capitalize;
}
.ListMenu .menu_name:hover, .ListMenu .name_sub_menu:hover{
    background: rgba(106, 133, 174, 0.5);
    border-radius: 8px;
    color: #FFC400;
}
.ListMenu .list_sub_menu{
    font-family: 'Inter';
    font-size: 14px;
    cursor: pointer;
    color: #FFFFFF;
    margin-left: 20px;
    display: none;
    opacity: 0;
    transition: all .2s cubic-bezier(.4,0,1,1) 0s;
}
.ListMenu .sub_menu_active{
    display: block;
    opacity: 1;
}
.ListMenu .icons_rotate{
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.ListMenu input[type="file"]{
    display: none;
}